<template>
    <div class="app-container common-list-page">
        <el-form
                :model="resetForm"
                :rules="resetFormRules"
                ref="resetForm"
                status-icon
                label-width="100px"
        >
            <el-form-item label="注册手机号" prop="phone">
                <el-input  v-model="resetForm.phone" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="短信验证" prop="code" class="pr">
                <el-input style="width: 60%" v-model="resetForm.code"></el-input>
                <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
                    <span v-show="show">获取验证码</span>
                    <span v-show="!show" class="count">{{count}} s</span>
                </button>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
                <el-input type="password" v-model="resetForm.password"  auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirm">
                <el-input type="password" v-model="resetForm.confirm" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click.native.prevent="toAmend">确认修改</el-button>
                <el-button type="primary" @click.native.prevent="goLogin">返回首页</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        data() {
            var validatePass = (rule, value, callback) => {
                if (!value) {
                    callback(new Error("请输入新密码"));
                } else if (value.toString().length < 7 || value.toString().length > 12) {
                    callback(new Error("密码长度为7-12位"));
                } else {
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请再次输入密码"));
                } else if (value !== this.resetForm.password) {
                    callback(new Error("两次输入密码不一致!"));
                } else {
                    callback();
                }
            };
            return {
                show: 'false',
                count: '',
                resetForm: {
                    //传给后台所需要的参数
                    phone: "",
                    code: "",
                    password: "",
                    confirm:''
                },
                resetFormRules: {
                    phone: [
                        { required: true, message: "请输入手机号码", trigger: "blur" },
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ],
                    code: [
                        { required: true, message: "请输入验证码", trigger: 'blur' }
                    ],
                    password: [
                        { required: true, validator: validatePass, trigger: "blur" },
                        { min: 8, max: 12, message: "请输入8-12位密码", trigger: "blur" },
                        {
                            pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                            message: "密码设置需要数字+字母+特殊符合",
                            trigger: "blur"
                        }
                    ],
                    confirm: [
                        { required: true, validator: validatePass2, trigger: "blur" },
                        { min: 8, max: 12, message: "请输入8-12位密码", trigger: "blur" },
                        {
                            pattern: '^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^(0-9a-zA-Z)]).{8,12}$',
                            message: "密码设置需要数字+字母+特殊符合",
                            trigger: "blur"
                        }
                    ]
                }
            };
        },
        methods: {
            toAmend() {
                this.$refs["resetForm"].validate(valid => {
                    if (valid) {
                        axios.post(`${this.$apiPath}/api/pm/login/updatePassword?code=`+ this.resetForm.code + '&phone=' + this.resetForm.phone + '&password=' + this.resetForm.password + '&confirm=' + this.resetForm.confirm).then(res=>{
                            if(res.data.success == true){
                                this.$alert('新密码修改成功，请登录', '提示', {
                                    confirmButtonText: '确定',
                                });
                                this.$router.replace('/login');
                            }else{
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        })
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });
            },
            goLogin(){
                this.$router.replace('/login');
            },
            // 获取短信验证码
            getCode() {
                //axios请求
                if(this.resetForm.phone == ''){
                    this.$alert('请输入手机号', '提示', {
                        confirmButtonText: '确定',
                    });
                }else {
                    // 验证码倒计时
                    if (!this.timer) {
                        this.count = 60;
                        this.show = false;
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= 60) {
                                this.count--;
                            } else {
                                this.show = true;
                                clearInterval(this.timer);
                                this.timer = null;
                            }
                        }, 1000);
                    }
                    axios.post(`${this.$apiPath}/api/pm/login/send?phone=`+ this.resetForm.phone).then(res=>{
                        if(res.data.success == true){
                            this.$message({
                                message: '验证码已发送至您手机，注意查收',
                                type: 'success'
                            });
                        }
                    })
                }
            },
        },
    };
</script>

<style scoped>
    body{
        background: #ffffff;
    }
    .el-form {
        width: 60%;
        margin: 50px auto 0;
        text-align: center;
    button {
        margin: 20px 0 0;
    }
    }
    .code-btn {
        width: 85px;
        height: 29px;
        position: absolute;
        right: 10px;
        z-index: 222;
        color: #409EFF;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        background-color: #fff;
        cursor: pointer;
    }
</style>
